<template>
<aside id="main-sidebar"  class="control-sidebar control-sidebar-dark">
  <!-- Create the tabs -->
  <ul class="nav nav-tabs nav-justified control-sidebar-tabs">
    <li class="nav-item"><a class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"><i
          class="fa fa-newspaper-o" data-toggle="tooltip" data-placement="bottom"
          data-original-title="Tools"></i></a></li>
    <li class="nav-item"><a class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
        <span class="fa-stack my-form-icon" data-toggle="tooltip" data-placement="bottom"
          data-original-title="Custom Forms Admin">
          <i class="fa fa-newspaper-o"></i>
          <i class="fa fa-user icon-myform"></i>
        </span>
      </a></li>
    <li class="nav-item"><a class="nav-link"  id="settings-tab" data-bs-toggle="tab" data-bs-target="#settings" type="button" role="tab" aria-controls="settings-tab" aria-selected="false"><i class="fa fa-wrench"
          data-toggle="tooltip" data-placement="bottom" data-original-title="Settings"></i></a></li>

    <li class="nav-item"><a class="nav-link"  id="my-settings-tab" data-bs-toggle="tab" data-bs-target="#my-settings" type="button" role="tab" aria-controls="my-settings-tab" aria-selected="false"><i class="fa fa-gear" data-toggle="tooltip"
          data-placement="bottom" data-original-title="My Settings"></i></a></li>
  </ul>
  <!-- Tab panes -->
  <div class="tab-content">
    <!-- Home tab content -->
    <div class="tab-pane right-menu-tab sidebar-menu show active" id="home" role="tabpanel" aria-labelledby="home-tab">
      <div>
        <label class="control-sidebar-subheading">
          <span class="fa-stack new-account-icon">
            <i class="fa fa-envelope-o"></i>
            <i class="fa fa-users icon-plus"></i>
          </span>
          <span>Mass Mailing</span>
        </label>
        <ul class="treeview-menu">
          <li>
            <a href="/pages/mailing-list.html">
              <i class="fa fa-list"></i>
              Mailing Lists
            </a>
          </li>
          <li>
            <a href="/pages/mailing-criteria.html">
              <i class="fa fa-globe"></i>
              Criteria
            </a>
          </li>
          <li>
            <a href="/pages/mailing-messages.html">
              <i class="fa fa-envelope"></i>
              Messages
            </a>
          </li>
          <li>
            <a href="/pages/mailing.html">
              <i class="fa fa-envelope-open"></i>
              Mailings
            </a>
          </li>
          <li>
            <a href="/pages/mailing-campaigns.html">
              <span class="fa-stack new-account-icon">
                <i class="fa fa-envelope"></i>
                <i class="fa fa-bullhorn icon-plus"></i> 
              </span>
              Campaigns
            </a>
          </li>
        </ul>
      </div>
      <div class="form-group">
        <ul class="treeview-menu root" style="display: block;">
          <li class="treeview">
            <a href="/pages/alerts.html">
              <i class="fa fa-exclamation-circle"></i>
              Alerts
            </a>
          </li>
          <li class="treeview">
            <a href="/pages/admin-forms.html">
              <i class="fa fa-list-alt"></i>
              Forms
            </a>
          </li>
         
          <li class="treeview">
            <a href="/pages/server-monitor.html">
              <i class="fa fa-clock-o"></i>
             Server Process Monitor
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="tab-pane right-menu-tab sidebar-menu fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
      <div class="form-group">
        <ul class="treeview-menu" style="display: block;">
          <li>
            <a href="/pages/configure-agentinterface.html">
              <i class="fa fa-globe"></i> 
              Configure Agent Interfaces
            </a>
          </li>
          <li>
            <a href="/pages/customerportal-settings.html">
              <i class="fa fa-support"></i> 
              Configure Customer Portal
            </a>
          </li>
          
          <li>
            <a href="/pages/chat-configuration.html">
              <i class="fa fa-support"></i> 
              Chat Configuration
            </a>
          </li>
          <li>
            <a href="/pages/workflow-configuration.html">
              <i class="fa fa-support"></i> 
              Configure Workflow
            </a>
          </li>
          <li>
            <a href="/pages/credit-memo.html">
              <i class="fa icon-c icon-memo-admin"></i> 
              Credit Memo Admin
            </a>
          </li>
          <li>
            <a href="/pages/quote-admin.html">
              <i class="fa icon-c icon-qoute-admin"></i> 
             Quote Admin
            </a>
          </li>
          <li>
            <a href="/pages/configure-agentemail.html">
               <i class="fa icon-c icon-quick-agent-email"></i> 
              Configure Agent Email
            </a>
          </li>
          <li>
            <a href="/pages/workflow-configuration.html" class="router-link-active router-link-exact-active" aria-current="page"><i class="fa fa-road"></i> Workflows</a>
          </li>          
        </ul>
      </div>
    </div>
    <!-- /.tab-pane -->
    <div class="tab-pane right-menu-tab sidebar-menu fade" id="settings" role="tabpanel" aria-labelledby="settings-tab">
      <div class="form-group">
        <label class="control-sidebar-subheading">
          <i class="fa fa-code-fork"></i>
          Segment Settings
        </label>
        <ul class="treeview-menu">
          <li>
            <button class="btn btn-primary btn-xs btn-new-segment" data-bs-toggle="collapse" data-bs-target="#createNewSegment" aria-expanded="false" aria-controls="createNewSegment">Create New Segment</button>
            <div class="new-segment-container collapse" id="createNewSegment">
              <div class="segment-container">
                <h3>New Segment</h3>
                <div class="form-group">
                  <label class="control-label">Enter Segment Name</label>
                  <input type="email" class="form-control" placeholder="">
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-primary btn-sm"
                    onclick="location.href = '/pages/new-segment.html';">Create Segment</button>
                  <button type="submit" class="btn btn-link btn-sm" ng-click="newsegment = !newsegment;">Cancel</button>
                </div>
              </div>
            </div>
          </li>
          <li>
            <a href="/pages/segment-settings.html">
              <i class="fa fa-circle-o"></i>
              Sales
            </a>
          </li>
          <li class="sub-segments">
            <a href="/pages/segment-settings.html">
              <i class="fa fa-circle-o"></i>
              Segment 1
            </a>
          </li>
          <li class="sub-segments">
            <a href="/pages/segment-sales.html">
              <i class="fa fa-circle-o"></i>
              Segment 2
            </a>
          </li>
          <li>
            <a href="/pages/segment-hr.html">
              <i class="fa fa-circle-o"></i>
              Human Resources
            </a>
          </li>
          <li>
            <a href="/pages/segment-customer-service.html">
              <i class="fa fa-circle-o"></i>
              Customer Service
            </a>
          </li>
        </ul>
      </div>
      <div class="form-group">
        <label class="control-sidebar-subheading">
          <i class="fa fa-gear"></i>Tenant Settings
        </label>
        <ul class="treeview-menu">
          <li>
            <a href="/pages/agents.html">
              <span class="fa icon-c icon-support"></span>
              Agents
            </a>
          </li>
          <li>
            <a href="/pages/departments.html">
              <i class="fa fa-object-group"></i>
              Departments
            </a>
          </li>
          <li>
            <a href="/pages/lists.html">
              <i class="fa fa-list"></i>
              Lists
            </a>
          </li>
          <!--<li>
            <a href="/pages/openid.html">
              <i class="fa fa-sign-in"></i>
              OpenID
            </a>
          </li>-->
          <li>
            <a href="/pages/service-levels.html">
              <i class="fa fa-cog"></i>
              Service Levels
            </a>
          </li>
          <li>
            <a href="/pages/skills.html">
              <i class="fa fa-paint-brush"></i>
              Skills
            </a>
          </li>
          <li>
            <a href="/pages/user-types.html">
              <i class="fa fa-user"></i>
              User Types
            </a>
          </li>
          <li>
            <a href="/pages/websites.html">
              <i class="fa fa-globe"></i>
              Websites
            </a>
          </li>
          <li>
            <a href="/pages/external-apis.html">
              <i class="fa fa-external-link"></i>
              External APIs
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Settings tab content -->
    <div class="tab-pane right-menu-tab sidebar-menu fade" id="my-settings" role="tabpanel" aria-labelledby="my-settings-tab">
      <h3 class="control-sidebar-heading">Color Scheme</h3>
     
      <h3 class="control-sidebar-heading">My Defaults</h3>
      <div class="row">
        <div class="form-group">
          <label class="col-12 control-label">Mailbox From</label>
          <div class="col-12">
            <select class="form-control">
              <option value="Select Topic 1">Sales</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <!-- /.tab-pane -->
  </div>
</aside>
<div class="control-sidebar-bg"></div>
</template>
<script>
export default {
  name: 'RightPanelMenuComponents'
}
</script>
