<template>
<!-- Left side column. contains the logo and sidebar -->
<aside class="main-sidebar left-menu" ref="sideBox" id="sideBox">
    <!-- sidebar: style can be found in sidebar.less -->
    <section class="sidebar">
        <!-- Sidebar user panel -->
        <div class="user-panel">
            <div class="pull-left user-panel">
                <a href="#"><i class="fa fa-circle text-success"></i> <span> Available</span></a>
                <a class="btn btn-primary btn-xs pull-right"><i class="fa  fa-comments blink"></i> <span>Take chat</span></a>
            </div>
        </div>
        <!-- search form -->
        <form action="#" method="get" class="sidebar-form">
            <div class="input-group">
                <input type="text" name="q" class="form-control" placeholder="Search Customer Info...">
                <span class="input-group-btn">
                    <button type="submit" name="search" id="search-btn" class="btn btn-flat">
                        <i class="fa fa-search"></i>
                    </button>
                </span>
            </div>
        </form>
        <!-- /.search form -->
        <!-- sidebar menu: : style can be found in sidebar.less -->

	  <ul class="sidebar-menu tree" admin-lte-tree >
					<li class="active treeview menu-open">
							<a href="/pages/dashboard">
								<i class="fa fa-dashboard"></i> <span>Dashboards</span>
								<span class="pull-right-container">
									<i class="fa fa-angle-left pull-right"></i>
								</span>
							</a>
							<ul class="treeview-menu" style="display: block;">
								<li>
									<a  href="/pages/dashboard">
									<i class="fa fa-circle-o"></i>
									My Dashboards
								</a>
							</li> 
							<li>
								<a  href="/segment-dashboard.html">
									<i class="fa fa-circle-o"></i>
									Segment Dashboard
								</a>
							</li> 
						</ul>
					</li>
					<li class="active treeview menu-open">
						<a href="#">
							<i class="fa fa-users"></i> <span>Customer Info</span>
							<span class="pull-right-container">
								<i class="fa fa-angle-left pull-right"></i>
							</span>
						</a>
						<ul class="treeview-menu" style="display: block;">
							
							<li>
								<a  href="/pages/new-contact.html">
								<i class="fa fa-user-plus"></i>
								New Contact
								</a>
							</li>
							<li>
								<a href="/pages/new-account.html">
									<span class="fa-stack new-account-icon">
									<i class="fa fa-users"></i>
									<i class="fa fa-plus icon-plus"></i> 
									</span>
									New Account
								</a>
							</li>
							<li>
								<a href="/pages/search-contacts.html">
									<i class="fa fa-search"></i>
									Search Contacts
								</a>
							</li>
							<li>
								<a href="/pages/contact-summary.html">
								<i class="fa fa-user"></i>
								Contact
								</a>
							</li>
							<li>
								<a href="/pages/account.html">
								<i class="fa fa-users"></i>
								Account
								</a>
							</li>
						</ul>	
					</li>   
					<li>
						<a href="/pages/interaction-search.html">
						<i class="fa fa-search"></i> <span>Search Interactions</span>
						</a>
					</li>
					<li class="active treeview menu-open">
						<a href="#">
							 <i class="fa fa-tasks"></i>
							 <span>My Work Queues</span>
							
						</a>
						<ul class="treeview-menu" style="display: block;">
							<li>
								<a href="/pages/my-message-inbox.html">
									<i class="fa fa-inbox"></i>
									My Message Inbox 
									<span class="pull-right-container">
										<small class="label pull-right bg-yellow">5</small>
									</span>
								</a>
							</li>
							<li>
								<a href="/pages/my-messages-inbox-qa-csr.html">
									<i class="fa fa-inbox"></i>
									My Message Inbox (QA CSR)
								</a>
							</li>
						<li>
							<a href="/pages/my-pending-messages.html">
								<i class="fa icon-c icon-msg-pending"></i>
								My Pending Messages 
								<span class="pull-right-container">
									<small class="label pull-right bg-yellow">2</small>
								</span>
							</a>
						</li>
						<li>
							<a href="/pages/my-messages-to-approve.html">
								<span class="fa-stack new-account-icon">
								<i class="fa fa-envelope-o"></i>
								<i class="fa fa-check icon-plus"></i> 
								</span>
								My Messages to Approve 
								<span class="pull-right-container">
									<small class="label pull-right bg-yellow">5</small>
								</span>
							</a>
						</li>
						<li>
							<a href="/pages/draft-messages.html">
								<i class="fa fa-save"></i>
								My Draft Messages
								<span class="pull-right-container">
									<small class="label pull-right bg-yellow">5</small>
								</span>
							</a>
						</li>
						<li>
							<a href="/pages/my-open-quotes.html">
								<i class="fa fa-file-text-o"></i>
								My Open Quotes
							</a>
						</li>
						<li>
							<a href="/pages/my-estimates.html">
								<i class="fa fa-file-text-o"></i>
								My Estimates
							</a>
						</li>
						<li>
							<a href="/pages/estimate-calendar.html">
								<i class="fa fa-file-text-o"></i>
								My Estimates Calendar
							</a>
						</li>
						</ul>	
					</li> 
					<li class="active treeview menu-open">
						<a href="#">
							<span class="fa-stack new-account-icon">
							  <i class="fa fa fa-tasks"></i>
							  <i class="fa fa-gear icon-plus"></i>
							</span>
							<span>Manage Work Queues</span>
							<span class="pull-right-container">
								<i class="fa fa-angle-left pull-right"></i>
							</span>
						</a>
						<ul class="treeview-menu" style="display: block;">
							<li>
								<a  href="/pages/manage-message.html">
									<span class="fa-stack new-account-icon">
									<i class="fa fa-envelope-o"></i>
									<i class="fa fa-gear icon-plus"></i> 
									</span>
									Manage Messages
								</a>
							</li>
							<li>
								<a href="/pages/manage-chat.html">
								<i class="fa fa-comments-o"></i>
								Manage Chats
								</a>
							</li>
							<li>
								<a href="/pages/loggedin-chat-agents.html">
									<span class="fa-stack new-account-icon">
										<i class="fa icon-c icon-support"></i>
										<i class="fa  fa-commenting icon-plus"></i> 
									</span>
									Logged in Chat Agents
								</a>
							</li>
							<li>
								<a href="/pages/manage-open-quotes.html">
									<span class="fa-stack new-account-icon">
									<i class="fa fa-file-text-o"></i>
									<i class="fa  fa-gear icon-plus"></i> 
									</span>
									Manage Open Quotes
								</a>
							</li>
							<li>
								<a href="/pages/manage-kb-article.html">
									<span class="fa-stack new-account-icon">
										<i class="fa fa-book"></i>
										<i class="fa  fa-gear icon-plus"></i> 
									</span>
									Manage KB Articles
								</a>
							</li>
							<li>
								<a href="/pages/agents-knowledge-base.html">
								<i class="fa fa-circle-o"></i>
								  Agents Knowledge Base
								</a>
							</li>
						</ul>	
					</li> 
					<li class="active treeview menu-open">
						<a href="#">
							<i class="fa icon-c icon-custom-forms"></i> <span>Custom Forms</span>
							<span class="pull-right-container">
								<i class="fa fa-angle-left pull-right"></i>
							</span>
						</a>
						<ul class="treeview-menu" style="display: block;">
							<li>
								<a  href="/pages/quick-agent-email.html">
								<i class="fa icon-c icon-quick-agent-email"></i> 
								Quick Agent Email
								</a>
							</li>
							<li>
								<a  href="/pages/submit-credit-memo.html">
									<i class="fa icon-c icon-memo-admin"></i> 
									Submit Credit Memo
								</a>
							</li>
							<li>
								<a  href="/pages/credit-memo-approval-form.html">
									<i class="fa icon-c icon-memo-admin"></i> 
									Credit Memo Approval Form
								</a>
							</li>
							
							<li>
								<a  href="/pages/submit-bug.html">
									<i class="fa icon-c icon-memo-admin"></i> 
									Bug Report Form
								</a>
							</li>
							<li>
								<a  href="/pages/enhancement-request.html">
									<i class="fa icon-c icon-memo-admin"></i> 
									Enhancement Request Form
								</a>
							</li>
							<li>
								<a  href="/pages/custom-aaq-form.html">
									<i class="fa icon-c icon-memo-admin"></i> 
									Escalation Form - FCC
								</a>
							</li>
							<li>
								<a  href="/pages/goleyinc-inbox-message.html">
									<i class="fa icon-c icon-memo-admin"></i> 
									Goley Inc. Message Inbox
								</a>
							</li>
							<li>
								<a  href="/pages/lookup.html">
									<i class="fa icon-c icon-memo-admin"></i> 
									Goley Lookup Table
								</a>
							</li>	
						</ul>	
					</li> 
					
					<li class="active treeview menu-open">
						<a href="#">
							<i class="fa fa-circle-o"></i> <span>Page Components</span>
							<span class="pull-right-container">
								<i class="fa fa-angle-left pull-right"></i>
							</span>
						</a>
						<ul class="treeview-menu" style="display: block;">
						    <li>
								<a  href="/pages/form.html">
								<i class="fa fa-circle-o"></i>
								 Chat Form
								</a>
							</li>
							<li>
								<a  href="/pages/form-validation.html">
								<i class="fa fa-circle-o"></i>
								 Form Validation
								</a>
							</li>
							 <li>
								<a  href="/pages/mail-popper.html">
								<i class="fa fa-circle-o"></i>	  
								  Mail Popper
								</a>
							</li>
							
							 <li>
								<a  href="/pages/Notifications.html">
								<i class="fa fa-circle-o"></i>
								 Notifications alert
								</a>
							</li>
							<li>
								<a  href="/pages/view-mode.html">
								<i class="fa fa-exchange"></i>
								Interaction (view mode)
								</a>
							</li>
							<li>
								<a  href="/pages/action-mode.html">
								<i class="fa fa-exchange"></i>
								Interaction (action mode)
								</a>
							</li>
							<li>
								<a href="/pages/agent-email.html">
								<i class="fa fa-envelope"></i>
								Send Agent Email
								</a>
							</li>
							<li>
								<a href="/pages/create-note.html">
								<i class="fa fa-file-text-o"></i>
								Create Note
								</a>
							</li>
							<li>
								<a href="/pages/create-ticket.html">
								<i class="fa fa-ticket"></i>
								Create Ticket
								</a>
							</li>
							<li>
								<a href="/pages/live-chat.html">
								<i class="fa fa-circle-o"></i>
								Agent Chat Panel
								</a>
							</li>

							<li>
								<a href="/pages/customer-chat-panel-1.html">
								<i class="fa fa-circle-o"></i>
								Customer Chat Panel 1 - Offline
								</a>
							</li>
							<li>
								<a href="/pages/customer-chat-panel-1-online.html">
								<i class="fa fa-circle-o"></i>
								Customer Chat Panel 1 - Online
								</a>
							</li>
							<li>
								<a href="/pages/customer-chat-panel-2.html">
								<i class="fa fa-circle-o"></i>
								Customer Chat Panel 2 - Offline
								</a>
							</li>
							<li>
								<a href="/pages/customer-chat-panel-2-online.html">
								<i class="fa fa-circle-o"></i>
								Customer Chat Panel 2 - Online
								</a>
							</li>
							<li>
								<a href="/">
								<i class="fa fa-circle-o"></i>
								Login Panel
								</a>
							</li>
							<li>
								<a href="/pages/examples/forget-password.html">
								<i class="fa fa-circle-o"></i>
								Forgot Password
								</a>
							</li>
							<li>
								<a href="/pages/examples/password-reset.html">
								<i class="fa fa-circle-o"></i>
									Password Reset
								</a>
							</li>
							<li>
								<a href="/pages/page-elements-examples.html">
								<i class="fa fa-circle-o"></i>
								Page Element Examples
								</a>
							</li>
							<li>
								<a href="/agent-page-login-error.html">
								<i class="fa fa-circle-o"></i>
								 Cutomer - Agent Login Error
								</a>
							</li>
							<li>
								<a href="/pre-logout-notice.html">
								<i class="fa fa-circle-o"></i>
									Pre Logout Notice
								</a>
							</li>
							<li>
								<a href="/logout-notice.html">
								<i class="fa fa-circle-o"></i>
									Logout Notice
								</a>
							</li>
							<li>
								<a href="/external-answer-page.html">
								<i class="fa fa-circle-o"></i>
								External Answer Page
								</a>
							</li>
							<li>
								<a href="/external-answer-page-success-msg.html">
								<i class="fa fa-circle-o"></i>
								External Answer Page - Success Msg
								</a>
							</li>
							<li>
								<a href="/external-answer-page-error-msg.html">
								<i class="fa fa-circle-o"></i>
								External Answer Page - Error Msg
								</a>
							</li>
							<li>
								<a href="/pages/icons.html">
								<i class="fa fa-circle-o"></i>
								Icons
								</a>
							</li>
						</ul>	
					</li>
				</ul>	
    </section>
			<span class="left-copyright">
				Styles are based on the AdminLTE Control Panel Theme for Bootstrap 3 Version 2.4.0.
			</span>
    <!-- /.sidebar -->
	</aside>


</template>
<script>

export default {
  name: 'SidebarComponents',
}
</script>
