import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Individual-1',
    component: Home,
    props: { Individual: true },
    meta: { title: 'iService | Home' } 
  },
  {
    path: '/login',
    name: 'Individual-2', 
    component: Home,
    props: { Individual: true },
    name: 'Dasboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Dashboard' } 
  },
  {
    path: '/google-connect',
    name: 'Individual-3', 
    props: { Individual: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/GoogleConnect.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Login' } 
  },
  {
    path: '/agent-page-login-error.html',
    name: 'Individual-5', 
    props: { Individual: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AgentPageLoginError.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Login' } 
  },
  {
    path: '/not-an-agent-error',
    name: 'Individual-4', 
    props: { Individual: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NotAgentError.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Login' } 
  },
  {
    path: '/pages/dashboard',
    name: 'Dashboard', 
    component: Home,
    props: { Individual: true },
    name: 'Dasboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Dashboard' } 
  },
  {
    path: '/pages/my-message-inbox.html',
    name: 'My message inbox',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MyMessageInbox.vue'),
    props: { Individual: false },
    meta: { title: 'iService | My Message Inbox' } 
  },
  {
    path: '/pages/my-pending-messages.html',
    name: 'My pending messages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MyPendingMessages.vue'),
    props: { Individual: false },
    meta: { title: 'iService | My Pending Message' } 
  },
  {
    path: '/pages/my-messages-to-approve.html',
    name: 'My messages to approve',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MyMessagesToApprove.vue'),
    props: { Individual: false },
    meta: { title: 'iService | My Message To Approve' } 
  },
  {
    path: '/pages/draft-messages.html',
    name: 'My Draft Messages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MyDraftMessages.vue'),
    props: { Individual: false },
    meta: { title: 'iService | My Draft Messages' } 
  },
  {
    path: '/pages/my-open-quotes.html',
    name: 'My Open Quotes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MyOpenQuotes.vue'),
    props: { Individual: false },
    meta: { title: 'iService | My Open Quotes' } 
  },
  {
    path: '/pages/new-contact.html',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
    props: { Individual: false },
    meta: { title: 'iService | New Contact' } 
  },
  {
    path: '/pages/new-account.html',
    name: 'New Account',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NewAccount.vue'),
    props: { Individual: false },
    meta: { title: 'iService | New Account' } 
  },
  {
    path: '/pages/contact-summary.html',
    name: 'Contact Summary',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactSummary.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Contact Summary' } 
  },
  {
    path: '/pages/view-mode.html',
    name: 'View Mode',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ViewMode.vue'),
    props: { Individual: false },
    meta: { title: 'iService | View Mode' } 
  },
  {
    path: '/pages/search-contacts.html',
    name: 'Search Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/SearchContact.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Search Contact' } 
  },
  {
    path: '/pages/interaction-search.html',
    name: 'Interaction Search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchInteractions.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Interaction Search' } 
  },
  {
    path: '/pages/manage-message.html',
    name: 'Manage Messages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ManageMessages.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Manage Message' } 
  },
  {
    path: '/pages/manage-chat.html',
    name: 'Manage Chat',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ManageChat.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Manage Chat' } 
  },
  {
    path: '/pages/manage-kb-article.html',
    name: 'Manage KB Article',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ManageKbArticle.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Manage KB Article' } 
  },
  {
    path: '/pages/agents-knowledge-base.html',
    name: 'agents knowledge base',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AgentsKnowledgeBase.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Agents Knowledge Base' } 
  },
  {
    path: '/pages/manage-kb-article-listview.html',
    name: 'Manage KB Article List View',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ManageKbArticleListView.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Manage kB Article' } 
  }
  ,
  {
    path: '/pages/new-kb-article.html',
    name: 'New KB Article',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NewKbArticle.vue'),
    props: { Individual: false },
    meta: { title: 'iService | New KB Article' } 
  }
  ,
  {
    path: '/pages/form.html',
    name: 'Forms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Form.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Form Validations' } 
  },
  {
    path: '/pages/notifications.html',
    name: 'Notifications',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Notifications.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Notifications' } 
  },
  {
    path: '/pages/new-user-autorization.html',
    name: 'Notifications',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NewUserAuth.vue'),
    props: { Individual: false },
    meta: { title: 'iService | New User Authorization Form' } 
  },
  {
    path: '/pages/mail-popper.html',
    name: 'Mail Popper',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MailPopper.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Mail Popper' } 
  },
  {
    path: '/pages/custom-settings.html',
    name: 'Custom Settings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CustomSettings.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Custom Settings'} 
  },
  {
    path: '/pages/customerportal-settings.html',
    name: 'Customer Portal Settings', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CustomerPortalSettings.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Custom Settings' } 
  },
  {
    path: '/pages/chat-panel-configuration.html',
    name: 'Chat Panel', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ChatPanelConfiguration.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Chat Panel' } 
  },
  {
    path: '/pages/chat-configuration.html',
    name: 'Chat Configuration List', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ChatConfigurationList.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Chat Panel' } 
  },
  {
    path: '/pages/server-monitor.html',
    name: 'Server Monitor', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ServerMonitor.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Server Monitor' } 
  },
  {
    path: '/pages/alerts.html',
    name: 'alerts', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Alerts.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Alerts' } 
  },
  {
    path: '/pages/segment-settings.html',
    name: 'segment settings', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/SegmentSettings.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Segment Settings' } 
  },
  ,
  {
    path: '/pages/agents.html',
    name: 'agents', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Agents.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Agents Settings' } 
  }
  ,
  {
    path: '/pages/departments.html',
    name: 'department', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Department.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Department Settings' } 
  },
  {
    path: '/pages/lists.html',
    name: 'list', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/List.vue'),
    props: { Individual: false },
    meta: { title: 'iService | List Settings' } 
  },
  {
    path: '/pages/openid.html',
    name: 'Open ID', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Openid.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Open ID Settings' } 
  },
  {
    path: '/pages/service-levels.html',
    name: 'Service Level', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ServiceLevel.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Service Level Settings' } 
  },
  {
    path: '/pages/skills.html',
    name: 'Skills', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Skills.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Skills Settings' } 
  },
  {
    path: '/pages/user-types.html',
    name: 'User Types', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/UserTypes.vue'),
    props: { Individual: false },
    meta: { title: 'iService | User Types Settings' } 
  },
  {
    path: '/pages/websites.html',
    name: 'Websites', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Websites.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Website Settings' } 
  },
  {
    path: '/pages/configure-agentinterface.html',
    name: 'Configure Agent Interface', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ConfigureAgentInterface.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Configure Agent Interface', 
    } 
  }
  ,
  {
    path: '/pages/quote-admin.html',
    name: 'Quote Admin', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/QuoteAdmin.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Quote Admin', 
    } 
  }
  ,
  {
    path: '/pages/mailing-list.html',
    name: 'Mailing List', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/MailingList.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Quote Admin', 
    } 
  } ,
  {
    path: '/pages/mailing-criteria.html',
    name: 'Criteria', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Criteria.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Criteria', 
    } 
  } ,
  {
    path: '/pages/mailing-messages.html',
    name: 'Melling Messages', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  component: () => import('../views/MailingMessages.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Mailing Messages', 
    } 
  },
  {
    path: '/pages/mailing.html',
    name: 'Mailing Messages', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  component: () => import('../views/MailingConfiguration.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Mailing Configuration', 
    } 
  },
  {
    path: '/pages/mailing-campaigns.html',
    name: 'Mailing Campaign', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/MailingCampaign.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Mailing Campaign', 
    } 
  },
  {
    path: '/pages/configure-agentemail.html',
    name: 'Configure Agent', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ConfigureAgentEmail.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Configure Agent Email', 
    } 
  }
  ,
  {
    path: '/pages/configure-agentemail2.html',
    name: 'Configure Agent Email', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ConfigureAgentEmail2.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Configure Agent Email', 
    } 
  }
  ,
  {
    path: '/pages/page-elements-examples.html',
    name: '{Page elemements }', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/PageElements.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Page elemements', 
    } 
  },
  {
    path: '/pages/enhancement-request.html',
    name: 'Enhancement Request Form', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/enhancementRequestForm.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Enhancement Request Form', 
    } 
  },
  {
    path: '/pages/custom-aaq-form.html',
    name: 'Custom aaq form - FCC tenanct', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/CustomAAQFormFCC.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Custom aaq form - FCC Tenant', 
    } 
  },
  {
    path: '/pages/external-answer-page.html',
    name: 'External answer page', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ExternalAnswerPage.vue'),
    props: { Individual: false },
    meta: { title: 'iService | External answer page', 
    } 
  },
  {
    path: '/pages/goleyinc-inbox-message.html',
    name: 'Goley Quotes List', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/GoleyincInboxMessage.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Goley Quotest List', 
    } 
  },
  {
    path: '/pages/workflow-configuration.html',
    name: 'Configure workflow', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/WorkFlowConfiguration.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Configure workflow ', 
    } 
  }
  ,
  {
    path: '/logout-notice.html',
    name: 'logout notice', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/LogoutNotice.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Logout Notice ', 
    } 
  },
  {
    path: '/pre-logout-notice.html',
    name: 'pre logout notice', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/PreLogoutNotice.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Logout Notice ', 
    } 
  },
  {
    path: '/pages/external-apis.html',
    name: 'pre logout notice', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ExternalAPIs.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Logout Notice ', 
    } 
  },
  {
    path: '/pages/form-validation.html',
    name: 'Form Validation', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/FormValidation.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Logout Notice ', 
    } 
    
  },
  {
    path: '/pages/icons.html',
    name: 'Form Validation', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Icons.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Logout Notice '} 
  },
  {
    path: '/pages/quick-agent-email.html',
    name: 'Quick Agent Email', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/QuickAgentEmail.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Quick Agent Email'} 
  },
  {
    path: '/pages/loggedin-chat-agents.html',
    name: 'Loggedin Chat Agents', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/LoggedinChatAgents.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Loggedin Chat Agents'} 
  },
  {
    path: '/pages/credit-memo-approval-form.html',
    name: 'Credit Memo Approval Form', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/CreditMemoApprovalForm.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Credit Memo Approval Form'} 
  },
  {
    path: '/pages/submit-credit-memo.html',
    name: 'Submit Credit Memo Form', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/SubmitCreditForm.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Submit Credit Memo Form'} 
  },
  {
    path: '/pages/submit-bug.html',
    name: 'Submit Bug Report', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/SubmitBugReport.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Submit Bug Report'} 
  },
  {
    path: '/pages/payment-collection.html',
    name: 'Payment Collection', 
    component: () => import('../views/PaymentCollection.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Payment Collection'} 
  },
  {
    path: '/pages/payment-receipt.html',
    name: 'Payment receipt', 
    component: () => import('../views/PaymentReceipt.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Payment Receipt'} 
  },
  {
    path: '/pages/Notifications.html',
    name: 'Payment receipt', 
    component: () => import('../views/Notification.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Notification'} 
  },  {
    path: '/pages/Lookup.html',
    name: 'Goley Lookup table', 
    component: () => import('../views/GoleyLookupTable.vue'),
    props: { Individual: false },
    meta: { title: 'iService | Goley Look table'} 
  }, {
    path: '/pages/my-estimates.html',
    name: 'My Estimates', 
    component: () => import('../views/MyEstimates.vue'),
    props: { Individual: false },
    meta: { title: 'iService | My Estimates'} 
  }, {
    path: '/pages/my-jobs.html',
    name: 'My Jobs', 
    component: () => import('../views/MyJobs.vue'),
    props: { Individual: false },
    meta: { title: 'iService | My Job'} 
  }, {
    path: '/pages/estimate-calendar.html',
    name: 'My Jobs', 
    component: () => import('../views/EstimateCalendar.vue'),
    props: { Individual: false },
    meta: { title: 'iService | My Job'} 
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from , next) => {
  document.title = to.meta.title;
  console.log(to.meta.title)
  next();
});

export default router
